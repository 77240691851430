
/**
 * Checks if element is vue/nuxt native wrapper element
 * @param {*} type vnode.type (slot, context)
 * @returns boolean
 */
export const isNativeWrapperElement = type => {
  const dataType = typeof type

  return (
    dataType === 'symbol' ||
    (dataType === 'object' && ['AsyncComponentWrapper', 'ClientOnly'].includes(type?.name))
  )
}

/**
 * Returns slot components
 * @param {object} slot either vue native or custom components
 * @returns Component
 */
const extractCustomComponents = (slot) => {
  if (!isNativeWrapperElement(slot.type) || !slot.children || typeof slot.children === 'string') {
    return slot
  }

  return slot.children.flatMap(extractCustomComponents)
}

/**
 * Vue seems to group slot components/non-html elements into a single component for some reason
 */
export const unwrapSlot = (slot) => slot
  .reduce((unwrappedSlot, slotItem) => {
    const slotContent = extractCustomComponents(slotItem)

    if (Array.isArray(slotContent)) {
      return [
        ...unwrappedSlot,
        ...slotContent
      ]
    }

    return [
      ...unwrappedSlot,
      slotContent
    ]
  }, [])

/**
 * Vue seems to group slot components/non-html elements into a single component for some reason
 */
export const slotLength = (slots) => slots
  .reduce((slotLength, slot) => (
    slotLength +
    unwrapSlot([slot]).length
  ), 0)

export const getCustomComponentParent = (context) => isNativeWrapperElement(context.parent.type)
  ? getCustomComponentParent(context.parent)
  : context.parent
